import { chatConfig as ChatConfig, chatWindow as ChatWindow } from 'kore-web-sdk';

/**
 * Global functions, available on the JavaScript window object.
 */
declare global {
    interface Window {
        elmoAiAssistantSetup: Function,
        elmoAiAssistant: Function,
    }
}

/**
 * Options that can be supplied to the elmoAiAssistantSetup function.
 * menu_url is optional, and won't be used if bot.jwt is supplied directly.
 */
interface ElmoAiAssistantOptions {
    menu_url: URL|string|null,
    bot: BotOptions,
}

/**
 * Kore AI internal BotOptions
 * Will change with KoreAI Library
 */
interface BotOptions {
    assertion: string,
    jwt: string,
    userIdentity: string,
    searchIndexID: string,
    clientId: string
    botInfo: {
        chatBot: string,
        taskBotId: string,
    }
}

/**
 * Response given by ELMO Kore AI Integration API
 * Contains the Credentials and Settings for the Bot.
 */
interface ElmoKoreAiResponseSettings {
    jwt: string,
    userIdentity: string,
    searchIndexID: string,
    clientId: string
    botInfo: {
        chatBot: string,
        taskBotId: string,
    }
}

/**
 * Initializes the Bot popup window within the page.
 */
class ElmoAssist {
    setup(_options: ElmoAiAssistantOptions) {

        const chatConfig = ChatConfig;

        const chatWindowInstance = new ChatWindow(chatConfig);
        const events = new EventTarget();

        // Internal state
        const state = {
            ready: false,
            show: false,
        };

        // Merge _options onto these defaults
        const options: ElmoAiAssistantOptions = {
            ...{
                menu_url: null,
                bot: {
                    jwt: null,
                    user: null,
                    botInfo: {},
                },
            },
            ..._options
        };

        /**
         * Menu entrypoint: The menu will call this function.
         */
        window.elmoAiAssistant = () => {
            state.show = !state.show;
            events.dispatchEvent(new Event('show.toggle'));
        }

        /**
         * Event: "show.toggle"
         * Call this to toggle visibility of the chat window.
         */
        events.addEventListener('show.toggle', (event) => {
            if (!state.ready) return;

            if (window?.document?.querySelector(".kore-chat-window.active")) {
                // Window is open? Use the Close button
                document.querySelector(".kore-chat-window-main-section .btn-action-close")?.click();
            } else {
                // Window is closed? Click the (invisible) text box
                document.querySelector(".kore-chat-window-main-section .avatar-bg")?.click();;
            }
        });

        /**
         * Call the API to load the Bot settings, or use existing settings.
         * Settings will only be loaded once.
         */
        const loadSettings = () => {
            return new Promise<any>((resolve, reject) => {
                // Use the Bot options if they're already set
                if (options?.bot?.jwt?.length > 0) {
                    resolve(options.bot);
                    return;
                }

                fetch(options.menu_url as URL)
                    .then(async (response: any) => {
                        if (!response.ok) {
                            return reject("Invalid API response");
                        }

                        const botData = (await response.json()).assistant as ElmoKoreAiResponseSettings;
                        options.bot = {...options.bot, ...botData}; // Merge API payload
                        resolve(options.bot);
                    })
                    .catch((err) => {
                        reject(err)
                    });
            });
        }

        /**
         * Initialize the Bot after loading settings from the API
         * The "options" variable must be populated
         */
        const loadBot = () => {

            // Monkey-patch JWT retrieval for backend fetch
            chatWindowInstance.getJWT = function (botOptions: BotOptions, callback: Function) {
                return new Promise((resolve, reject) => {
                    loadSettings()
                        .then((bot: ElmoKoreAiResponseSettings) => {
                            if (!bot.jwt || !bot.clientId) {
                                console.error("Invalid payload from configuration API");
                            }

                            // Configure bot from API
                            botOptions.jwt = bot.jwt;
                            botOptions.assertion = bot.jwt;
                            botOptions.botInfo = bot.botInfo;
                            botOptions.clientId = bot.clientId;
                            botOptions.userIdentity = bot.userIdentity;

                            // Impart ready state
                            state.ready = true;

                            // Callback, if provided
                            callback && callback(null, botOptions);

                            // Resolve JWT
                            resolve({
                                jwt: bot.jwt,
                            });
                        })
                        .catch((e) => {
                            console.error("Failed to load ELMO AI Assist Settings", e);
                        });
                });
            };

            // Hack on the Kore AI Styles to suite our needs.
            // This is highly brittle CSS wrangling, likely to break between versions! Comments indicate intent.
            // These styles are overly-specific in order to win a CSS specificity-arms-race with the base KoreAI styles.
            const style = document.createElement('style');

             style.textContent
                 // Fix z-index to raise above other z-indexed elements, but below Pendo
                 = '.kore-chat-window-main-section { z-index: 18000; }'
                 // Hide the chat window when minimised. Only show the main chat window.
                 + '.kore-chat-window-main-section.minimize-chat { display: none !important; }'
                 // Hide the Minimize avatar which can close the chat window
                 + '.kore-chat-window-main-section .avatar-minimize { display: none !important; }'
                 // Hide "powered by koreai" footers
                 + '.welcome-chat-section footer, .powerdby-info { display: none !important; }'
                 // Remove span-based hover behavior for answer snippets
                 + '.sa-answer-block .sa-answer-result-block .sa-answer-result-sub-block:hover .sa-answer-result-heading { color: inherit; font-weight: normal !important;}'
                 // follow the UI design: https://www.figma.com/design/2NaW0YYHj8YHvKQRXNi5Zr/ChatBot-UI?node-id=118-1686&p=f&t=9XFq8v1mgaHfrBpn-0
                 + '.chat-widget-header .info-content-data .bot_icon { height: 40px; width: 40px; border-radius: 8px; }'
                 + ':root { --avatar-bg: #D6E4FF; --avatar-icon-color: #1D39C4; --chat-widget-header-bg: #FAFAFA; --chat-widget-compose-bar-bg: #FAFAFA; --chat-widget-header-border: #E4E4E4; }'
                 + ':root { --chat-widget-box-shadow: 0px 4px 18px 6px rgba(0, 0, 0, 0.04), 0px 0px 0px 1px rgba(0, 0, 0, 0.04); }'
                 + '.chat-widget-header .info-content-data .content-text h2 { font-size: 14px; color: #737373; }'
                 + '.chat-widgetwrapper-main-container.minimizeQuick { border-color: #E4E4E4; }'
                 + '.bot-bubble-comp .bot-bubble-content .bubble-msg-with-img .bubble-msg { border-radius: 16px 16px 16px 4px; padding: 14px 16px 14px 16px; }'
                 + '.agent-bubble-comp .agent-bubble-content .bubble-msg-with-img .bubble-msg { border-radius: 16px 16px 4px 16px; padding: 14px 16px 14px 16px; }'
                 + '.end-of-history-separator { padding: 24px; }'
                 + '.chat-widget-composebar .compose-bar-wrapper .send-btn { width: 40px; height: 40px; border-radius: 8px; }'
                 + '.chat-widget-composebar .compose-bar-wrapper .send-btn svg { height: 20px; width: 20px; margin: 9px}'
                 + ':root { --sdk-global-theme-color: #10239E; }'
                 + ':root { --chat-widget-compose-bar-border: #E4E4E4; --chat-widget-compose-bar-gb: #FAFAFA; }'
                 + '.chat-widget-composebar .compose-bar-wrapper .compose-text-area .typing-text-area { border-radius: 8px; }'
                 + ':root { --chat-widget-compose-bar-text-area-border: #E4E4E4; }'
                 + '.chat-widget-header .back-to-chat { display: none !important; }'
                 + '.bot-bubble-comp .bot-bubble-content { padding-left: 0px; padding-right: 10px; }'
                 + '.agent-bubble-comp .agent-bubble-content { padding-left: 10px; padding-right: 0px; }'
                 + '.bot-bubble-comp {padding-left: 24px; padding-right: 24px;}'
                 + '.agent-bubble-comp {padding-left: 24px; padding-right: 24px;}'
                 + '.sa-answer-block {padding-left: 24px !important; padding-right: 24px !important;}'
                 + '.sa-answer-block .sa-answer-result-block .sa-answer-feedback-block .sa-answer-left .sa-answer-text { color: #10239E !important; }'
                 + '.sa-answer-block .sa-answer-result-block { border-top-width: 1px !important; border-bottom: 4px solid #10239E; border-radius: 16px 16px 2px 2px !important; box-shadow:  0px 0px 10px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.04) !important; }'
                 + '.sa-answer-img path { fill: #10239E !important; }'
                 + '.sa-answer-block .sa-answer-result-block .sa-answer-result-heading { font-weight: inherit !important; }'
                 + '.date-saperator .line-border { width: 109px; margin: 0; }'
                 + '.date-saperator { margin: 0; }'
             ;
            document.head.append(style);

            // Reveal!
            chatWindowInstance.show(chatConfig);
        }

        /**
         * Run the Bot
         */
        loadSettings().then(loadBot);
    }
}

export const elmoAssist = new ElmoAssist;
